import { Textarea } from "@/components/ui/textarea";
import {
  UpdatePlotMutationVariables,
  useFableBriefQuery,
  useUpdatePlotMutation,
} from "@/gql/__generated__/gql_types";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mutationDebounceMS, mutationDebounceOptions } from "./utils";

export const FableBriefPlot = () => {
  const { id: fableBriefId } = useParams() as { id: string };
  const { data } = useFableBriefQuery({
    variables: { fableBriefId },
    onCompleted: ({ fableBrief }) => {
      if (fableBrief?.fableBrief) {
        setPlot(fableBrief.fableBrief.plot);
      }
      //
    },
  });
  const fableBrief = data?.fableBrief?.fableBrief;
  const [plot, setPlot] = useState(fableBrief?.plot ?? "");

  const [updatePlot] = useUpdatePlotMutation();
  const updatePlotWithDebounce = useCallback(
    debounce(
      ({ variables }: { variables: UpdatePlotMutationVariables }) => {
        updatePlot({ variables });
      },
      mutationDebounceMS,
      mutationDebounceOptions,
    ),
    [updatePlot],
  );

  useEffect(() => {
    updatePlotWithDebounce({
      variables: {
        input: {
          fableBriefId,
          plot,
        },
      },
    });
  }, [plot]);
  return (
    <div>
      <h1>Plot</h1>
      <div className="flex">
        <div className="w-1/3">
          <p>How to create the plot</p>
        </div>
        <div className="w-2/3">
          <Textarea value={plot} onChange={(e) => setPlot(e.target.value)} />
        </div>
      </div>
    </div>
  );
};
