import { Root } from "./Root";
import { Error } from "./Error";
import { Home } from "./Home";
import { Fable } from "./Fable";
import { FableBrief } from "./FableBrief";
import { FableBriefCharacters } from "./FableBriefCharacters";
import { FableBriefPlot } from "./FableBriefPlot";
import { FableBriefSetting } from "./FableBriefSetting";
import { FableBriefThemes } from "./FableBriefThemes";
import { FableBriefLanguage } from "./FableBriefLanguage";
import { FableBriefIllustrations } from "./FableBriefIllustrations";

export const routes = [
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "fables/:id",
        element: <Fable />,
      },
      {
        path: "briefs/:id",
        element: <FableBrief />,
        children: [
          {
            path: "characters",
            element: <FableBriefCharacters />,
          },
          {
            path: "plot",
            element: <FableBriefPlot />,
          },
          {
            path: "setting",
            element: <FableBriefSetting />,
          },
          {
            path: "themes",
            element: <FableBriefThemes />,
          },
          {
            path: "language",
            element: <FableBriefLanguage />,
          },
          {
            path: "illustrations",
            element: <FableBriefIllustrations />,
          },
        ],
      },
    ],
  },
];
