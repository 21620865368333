import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  useCreateFableBriefMutation,
  useFablesQuery,
} from "@/gql/__generated__/gql_types";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const { data } = useFablesQuery();
  const [createFableBrief] = useCreateFableBriefMutation();
  const fables = data?.fables ?? [];
  const navigate = useNavigate();

  const onClickView = () => () => {
    console.log("clicking");
    //
  };

  const onClickCreate = () => {
    const variables = {
      input: {
        name: "asd",
      },
    };
    createFableBrief({
      variables,
      onCompleted: ({ createFableBrief }) => {
        navigate(`/briefs/${createFableBrief?.fableBrief?.id}`);
      },
    });
  };
  return (
    <div>
      <div className="grid grid-rows-12 grid-cols-12 gap-4">
        {fables.map((fable) => {
          return (
            <div className="col-span-4">
              <Card>
                <div>{fable.id}</div>
                <div>
                  <Button onClick={onClickView()}>View</Button>
                </div>
              </Card>
            </div>
          );
        })}
        <Card>
          <Button onClick={onClickCreate}>Create Fable</Button>
        </Card>
      </div>
    </div>
  );
};
