import React, { Dispatch, SetStateAction } from "react";
import { useTheme } from "./ThemeProvider";
import clsx from "clsx";

interface Step {
  name: string;
  completion: number; // Completion percentage from 0 to 100
}

interface StepperProps {
  steps: Step[];
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
}

const getStrokeColor = (completion: number): string => {
  // Adjusting the stroke color for the active step
  if (completion <= 25) return "#ef4444"; // red-500
  if (completion > 50 && completion < 100) return "#facc15"; // yellow-500
  if (completion === 100) return "#22c55e"; // green-500
  return "#e5e7eb"; // gray-200
};

const CircularProgress: React.FC<{ completion: number; isActive: boolean }> = ({
  completion,
  isActive,
}) => {
  const { theme } = useTheme();
  const radius = 35;
  const circumference = radius * 2 * Math.PI;
  const stroke = 10;
  const diameter = 70;
  const svgLength = diameter + 2 * stroke;
  const offset = circumference - (completion / 100) * circumference;

  return (
    <svg
      className={clsx({
        border: isActive,
      })}
      width={svgLength}
      height={svgLength}
    >
      <circle
        stroke={"#f9fafb"} // Adjust background circle color based on active state
        cx={svgLength / 2}
        cy={svgLength / 2}
        r={radius}
        strokeWidth="4"
        fill="transparent"
      />
      <circle
        stroke={getStrokeColor(completion)}
        cx={svgLength / 2}
        cy={svgLength / 2}
        r={radius}
        strokeWidth="8"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        fill="transparent"
        transform={`rotate(-90 ${svgLength / 2} ${svgLength / 2})`}
      />
      <text
        x={svgLength / 2}
        y={svgLength / 2}
        fill={theme === "light" ? "black" : "white"}
        stroke={theme === "light" ? "black" : "white"}
        fontSize="16"
        textAnchor="middle"
        dominantBaseline="central"
      >{`${completion}%`}</text>
    </svg>
  );
};

const Step = ({
  step,
  activeStep,
  setActiveStep,
}: {
  step: Step;
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div
      key={step.name}
      className="flex flex-col items-center flex-grow cursor-pointer"
      onClick={() => setActiveStep(step.name)}
    >
      <CircularProgress
        completion={step.completion}
        isActive={step.name === activeStep}
      />
      <div
        className={`mt-2 text-sm text-center ${step.name === activeStep ? "font-bold" : ""}`}
      >
        {step.name}
      </div>
    </div>
  );
};

export const Stepper: React.FC<StepperProps> = ({
  steps,
  activeStep,
  setActiveStep,
}) => {
  return (
    <div className="flex justify-between space-x-4 w-full p-4">
      {steps.map((step) => (
        <Step
          key={step.name}
          step={step}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ))}
    </div>
  );
};
