import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

type ErrorMessage = {
  message: string;
  statusText: string;
};

const ErrorPage = () => {
  const e = useRouteError() as ErrorMessage;
  const navigate = useNavigate();

  useEffect(() => {
    console.error("Unhandled App Error", e);
  }, [e]);

  return (
    <div
      id="error-page"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ marginTop: 100 }}>
        <h2>Oops!</h2>
      </div>
      <div style={{ marginTop: 20 }}>
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
      <div style={{ marginTop: 20 }}>
        <p>
          <i>{e.statusText || e.message}</i>
        </p>
      </div>
      <div style={{ marginTop: 100 }}>
        <button onClick={() => navigate("/")}>Go Gome</button>
      </div>
      <button
        onClick={() => {
          throw new Error("Test Error!");
        }}
      >
        Error
      </button>
    </div>
  );
};

export { ErrorPage as Error };
