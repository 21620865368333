import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type ParamMessageProps = {
  type?: "info" | "warn" | "success";
  message: string;
};

const variantForMessage = (paramMessage: ParamMessageProps) => {
  if (paramMessage.type === "warn") {
    return "error";
  } else if (paramMessage.type === "success") {
    return "success";
  } else {
    return "info";
  }
};

const ParamMessage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const encoded = searchParams.get("paramMessages");
    if (encoded) {
      const decoded = atob(encoded);
      const json = JSON.parse(decoded);
      json.map((message: ParamMessageProps) => {
        enqueueSnackbar(message.message, {
          variant: variantForMessage(message),
        });
      });
      searchParams.delete("paramMessages");
      setSearchParams(searchParams);
    }
  }, [searchParams]);
  return null;
};

export default ParamMessage;
