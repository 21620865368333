import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type CreateDog = {
  __typename?: "CreateDog";
  dog?: Maybe<Dog>;
  errors: Array<Error>;
};

export type CreateDogInput = {
  name: Scalars["String"]["input"];
};

export type CreateFable = {
  __typename?: "CreateFable";
  errors: Array<Error>;
  fable?: Maybe<Fable>;
};

export type CreateFableBrief = {
  __typename?: "CreateFableBrief";
  errors: Array<Error>;
  fableBrief?: Maybe<FableBrief>;
};

export type CreateFableBriefInput = {
  name: Scalars["String"]["input"];
};

export type CreateFableInput = {
  fableBriefId: Scalars["ID"]["input"];
};

export type Dog = {
  __typename?: "Dog";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  processed: Scalars["Boolean"]["output"];
};

export type Error = {
  __typename?: "Error";
  message: Scalars["String"]["output"];
  path?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type Fable = {
  __typename?: "Fable";
  id: Scalars["ID"]["output"];
  pages: Array<Page>;
  progress: Scalars["Int"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FableBrief = {
  __typename?: "FableBrief";
  characters: Scalars["String"]["output"];
  fable: Fable;
  id: Scalars["ID"]["output"];
  illustrations: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  plot: Scalars["String"]["output"];
  setting: Scalars["String"]["output"];
  themes: Scalars["String"]["output"];
};

export type FableBriefQueryInput = {
  __typename?: "FableBriefQueryInput";
  errors: Array<Error>;
  fableBrief?: Maybe<FableBrief>;
  id: Scalars["ID"]["output"];
};

export type FableQueryInput = {
  __typename?: "FableQueryInput";
  errors: Array<Error>;
  fable?: Maybe<Fable>;
  id: Scalars["ID"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  createDog?: Maybe<CreateDog>;
  createFable?: Maybe<CreateFable>;
  createFableBrief?: Maybe<CreateFableBrief>;
  updateCharacters?: Maybe<UpdateCharacters>;
  updateIllustrations?: Maybe<UpdateIllustrations>;
  updateLanguage?: Maybe<UpdateLanguage>;
  updatePlot?: Maybe<UpdatePlot>;
  updateSetting?: Maybe<UpdateSetting>;
  updateThemes?: Maybe<UpdateThemes>;
};

export type MutationCreateDogArgs = {
  input: CreateDogInput;
};

export type MutationCreateFableArgs = {
  input: CreateFableInput;
};

export type MutationCreateFableBriefArgs = {
  input: CreateFableBriefInput;
};

export type MutationUpdateCharactersArgs = {
  input: UpdateCharactersInput;
};

export type MutationUpdateIllustrationsArgs = {
  input: UpdateIllustrationsInput;
};

export type MutationUpdateLanguageArgs = {
  input: UpdateLanguageInput;
};

export type MutationUpdatePlotArgs = {
  input: UpdatePlotInput;
};

export type MutationUpdateSettingArgs = {
  input: UpdateSettingInput;
};

export type MutationUpdateThemesArgs = {
  input: UpdateThemesInput;
};

export type Page = {
  __typename?: "Page";
  id: Scalars["ID"]["output"];
  illustrationDescription: Scalars["String"]["output"];
  illustrationUrl?: Maybe<Scalars["String"]["output"]>;
  pageNumber: Scalars["Int"]["output"];
  text: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  dogs: Array<Dog>;
  fable?: Maybe<FableQueryInput>;
  fableBrief?: Maybe<FableBriefQueryInput>;
  fables: Array<Fable>;
};

export type QueryFableArgs = {
  fableId: Scalars["ID"]["input"];
};

export type QueryFableBriefArgs = {
  fableBriefId: Scalars["ID"]["input"];
};

export type UpdateCharacters = {
  __typename?: "UpdateCharacters";
  errors: Array<Error>;
  fableBrief?: Maybe<FableBrief>;
};

export type UpdateCharactersInput = {
  characters: Scalars["String"]["input"];
  fableBriefId: Scalars["ID"]["input"];
};

export type UpdateIllustrations = {
  __typename?: "UpdateIllustrations";
  errors: Array<Error>;
  fableBrief?: Maybe<FableBrief>;
};

export type UpdateIllustrationsInput = {
  fableBriefId: Scalars["ID"]["input"];
  illustrations: Scalars["String"]["input"];
};

export type UpdateLanguage = {
  __typename?: "UpdateLanguage";
  errors: Array<Error>;
  fableBrief?: Maybe<FableBrief>;
};

export type UpdateLanguageInput = {
  fableBriefId: Scalars["ID"]["input"];
  language: Scalars["String"]["input"];
};

export type UpdatePlot = {
  __typename?: "UpdatePlot";
  errors: Array<Error>;
  fableBrief?: Maybe<FableBrief>;
};

export type UpdatePlotInput = {
  fableBriefId: Scalars["ID"]["input"];
  plot: Scalars["String"]["input"];
};

export type UpdateSetting = {
  __typename?: "UpdateSetting";
  errors: Array<Error>;
  fableBrief?: Maybe<FableBrief>;
};

export type UpdateSettingInput = {
  fableBriefId: Scalars["ID"]["input"];
  setting: Scalars["String"]["input"];
};

export type UpdateThemes = {
  __typename?: "UpdateThemes";
  errors: Array<Error>;
  fableBrief?: Maybe<FableBrief>;
};

export type UpdateThemesInput = {
  fableBriefId: Scalars["ID"]["input"];
  themes: Scalars["String"]["input"];
};

export type CreateDogMutationVariables = Exact<{
  input: CreateDogInput;
}>;

export type CreateDogMutation = {
  __typename?: "Mutation";
  createDog?: {
    __typename?: "CreateDog";
    dog?: { __typename?: "Dog"; name: string } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type CreateFableMutationVariables = Exact<{
  input: CreateFableInput;
}>;

export type CreateFableMutation = {
  __typename?: "Mutation";
  createFable?: {
    __typename?: "CreateFable";
    fable?: { __typename?: "Fable"; id: string } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type CreateFableBriefMutationVariables = Exact<{
  input: CreateFableBriefInput;
}>;

export type CreateFableBriefMutation = {
  __typename?: "Mutation";
  createFableBrief?: {
    __typename?: "CreateFableBrief";
    fableBrief?: { __typename?: "FableBrief"; id: string } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type DogsQueryVariables = Exact<{ [key: string]: never }>;

export type DogsQuery = {
  __typename?: "Query";
  dogs: Array<{
    __typename?: "Dog";
    id: string;
    name: string;
    processed: boolean;
  }>;
};

export type FableQueryVariables = Exact<{
  fableId: Scalars["ID"]["input"];
}>;

export type FableQuery = {
  __typename?: "Query";
  fable?: {
    __typename?: "FableQueryInput";
    fable?: {
      __typename?: "Fable";
      id: string;
      title?: string | null;
      progress: number;
      pages: Array<{
        __typename?: "Page";
        id: string;
        text: string;
        pageNumber: number;
        illustrationDescription: string;
        illustrationUrl?: string | null;
      }>;
    } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type FableBriefQueryVariables = Exact<{
  fableBriefId: Scalars["ID"]["input"];
}>;

export type FableBriefQuery = {
  __typename?: "Query";
  fableBrief?: {
    __typename?: "FableBriefQueryInput";
    fableBrief?: {
      __typename?: "FableBrief";
      id: string;
      characters: string;
      plot: string;
      setting: string;
      themes: string;
      language: string;
      illustrations: string;
    } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type FablesQueryVariables = Exact<{ [key: string]: never }>;

export type FablesQuery = {
  __typename?: "Query";
  fables: Array<{ __typename?: "Fable"; id: string }>;
};

export type UpdateCharactersMutationVariables = Exact<{
  input: UpdateCharactersInput;
}>;

export type UpdateCharactersMutation = {
  __typename?: "Mutation";
  updateCharacters?: {
    __typename?: "UpdateCharacters";
    fableBrief?: {
      __typename?: "FableBrief";
      id: string;
      characters: string;
    } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type UpdateIllustrationsMutationVariables = Exact<{
  input: UpdateIllustrationsInput;
}>;

export type UpdateIllustrationsMutation = {
  __typename?: "Mutation";
  updateIllustrations?: {
    __typename?: "UpdateIllustrations";
    fableBrief?: {
      __typename?: "FableBrief";
      id: string;
      illustrations: string;
    } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type UpdateLanguageMutationVariables = Exact<{
  input: UpdateLanguageInput;
}>;

export type UpdateLanguageMutation = {
  __typename?: "Mutation";
  updateLanguage?: {
    __typename?: "UpdateLanguage";
    fableBrief?: {
      __typename?: "FableBrief";
      id: string;
      language: string;
    } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type UpdatePlotMutationVariables = Exact<{
  input: UpdatePlotInput;
}>;

export type UpdatePlotMutation = {
  __typename?: "Mutation";
  updatePlot?: {
    __typename?: "UpdatePlot";
    fableBrief?: { __typename?: "FableBrief"; id: string; plot: string } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type UpdateSettingMutationVariables = Exact<{
  input: UpdateSettingInput;
}>;

export type UpdateSettingMutation = {
  __typename?: "Mutation";
  updateSetting?: {
    __typename?: "UpdateSetting";
    fableBrief?: {
      __typename?: "FableBrief";
      id: string;
      setting: string;
    } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export type UpdateThemesMutationVariables = Exact<{
  input: UpdateThemesInput;
}>;

export type UpdateThemesMutation = {
  __typename?: "Mutation";
  updateThemes?: {
    __typename?: "UpdateThemes";
    fableBrief?: {
      __typename?: "FableBrief";
      id: string;
      themes: string;
    } | null;
    errors: Array<{ __typename?: "Error"; message: string }>;
  } | null;
};

export const CreateDogDocument = gql`
  mutation CreateDog($input: CreateDogInput!) {
    createDog(input: $input) {
      dog {
        name
      }
      errors {
        message
      }
    }
  }
`;
export type CreateDogMutationFn = Apollo.MutationFunction<
  CreateDogMutation,
  CreateDogMutationVariables
>;

/**
 * __useCreateDogMutation__
 *
 * To run a mutation, you first call `useCreateDogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDogMutation, { data, loading, error }] = useCreateDogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDogMutation,
    CreateDogMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDogMutation, CreateDogMutationVariables>(
    CreateDogDocument,
    options,
  );
}
export type CreateDogMutationHookResult = ReturnType<
  typeof useCreateDogMutation
>;
export type CreateDogMutationResult = Apollo.MutationResult<CreateDogMutation>;
export type CreateDogMutationOptions = Apollo.BaseMutationOptions<
  CreateDogMutation,
  CreateDogMutationVariables
>;
export const CreateFableDocument = gql`
  mutation CreateFable($input: CreateFableInput!) {
    createFable(input: $input) {
      fable {
        id
      }
      errors {
        message
      }
    }
  }
`;
export type CreateFableMutationFn = Apollo.MutationFunction<
  CreateFableMutation,
  CreateFableMutationVariables
>;

/**
 * __useCreateFableMutation__
 *
 * To run a mutation, you first call `useCreateFableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFableMutation, { data, loading, error }] = useCreateFableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFableMutation,
    CreateFableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFableMutation, CreateFableMutationVariables>(
    CreateFableDocument,
    options,
  );
}
export type CreateFableMutationHookResult = ReturnType<
  typeof useCreateFableMutation
>;
export type CreateFableMutationResult =
  Apollo.MutationResult<CreateFableMutation>;
export type CreateFableMutationOptions = Apollo.BaseMutationOptions<
  CreateFableMutation,
  CreateFableMutationVariables
>;
export const CreateFableBriefDocument = gql`
  mutation CreateFableBrief($input: CreateFableBriefInput!) {
    createFableBrief(input: $input) {
      fableBrief {
        id
      }
      errors {
        message
      }
    }
  }
`;
export type CreateFableBriefMutationFn = Apollo.MutationFunction<
  CreateFableBriefMutation,
  CreateFableBriefMutationVariables
>;

/**
 * __useCreateFableBriefMutation__
 *
 * To run a mutation, you first call `useCreateFableBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFableBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFableBriefMutation, { data, loading, error }] = useCreateFableBriefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFableBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFableBriefMutation,
    CreateFableBriefMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFableBriefMutation,
    CreateFableBriefMutationVariables
  >(CreateFableBriefDocument, options);
}
export type CreateFableBriefMutationHookResult = ReturnType<
  typeof useCreateFableBriefMutation
>;
export type CreateFableBriefMutationResult =
  Apollo.MutationResult<CreateFableBriefMutation>;
export type CreateFableBriefMutationOptions = Apollo.BaseMutationOptions<
  CreateFableBriefMutation,
  CreateFableBriefMutationVariables
>;
export const DogsDocument = gql`
  query Dogs {
    dogs {
      id
      name
      processed
    }
  }
`;

/**
 * __useDogsQuery__
 *
 * To run a query within a React component, call `useDogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDogsQuery(
  baseOptions?: Apollo.QueryHookOptions<DogsQuery, DogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DogsQuery, DogsQueryVariables>(DogsDocument, options);
}
export function useDogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DogsQuery, DogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DogsQuery, DogsQueryVariables>(
    DogsDocument,
    options,
  );
}
export function useDogsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DogsQuery, DogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DogsQuery, DogsQueryVariables>(
    DogsDocument,
    options,
  );
}
export type DogsQueryHookResult = ReturnType<typeof useDogsQuery>;
export type DogsLazyQueryHookResult = ReturnType<typeof useDogsLazyQuery>;
export type DogsSuspenseQueryHookResult = ReturnType<
  typeof useDogsSuspenseQuery
>;
export type DogsQueryResult = Apollo.QueryResult<DogsQuery, DogsQueryVariables>;
export const FableDocument = gql`
  query Fable($fableId: ID!) {
    fable(fableId: $fableId) {
      fable {
        id
        title
        progress
        pages {
          id
          text
          pageNumber
          illustrationDescription
          illustrationUrl
        }
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useFableQuery__
 *
 * To run a query within a React component, call `useFableQuery` and pass it any options that fit your needs.
 * When your component renders, `useFableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFableQuery({
 *   variables: {
 *      fableId: // value for 'fableId'
 *   },
 * });
 */
export function useFableQuery(
  baseOptions: Apollo.QueryHookOptions<FableQuery, FableQueryVariables> &
    ({ variables: FableQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FableQuery, FableQueryVariables>(
    FableDocument,
    options,
  );
}
export function useFableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FableQuery, FableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FableQuery, FableQueryVariables>(
    FableDocument,
    options,
  );
}
export function useFableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FableQuery,
    FableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FableQuery, FableQueryVariables>(
    FableDocument,
    options,
  );
}
export type FableQueryHookResult = ReturnType<typeof useFableQuery>;
export type FableLazyQueryHookResult = ReturnType<typeof useFableLazyQuery>;
export type FableSuspenseQueryHookResult = ReturnType<
  typeof useFableSuspenseQuery
>;
export type FableQueryResult = Apollo.QueryResult<
  FableQuery,
  FableQueryVariables
>;
export const FableBriefDocument = gql`
  query FableBrief($fableBriefId: ID!) {
    fableBrief(fableBriefId: $fableBriefId) {
      fableBrief {
        id
        characters
        plot
        setting
        themes
        language
        illustrations
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useFableBriefQuery__
 *
 * To run a query within a React component, call `useFableBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useFableBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFableBriefQuery({
 *   variables: {
 *      fableBriefId: // value for 'fableBriefId'
 *   },
 * });
 */
export function useFableBriefQuery(
  baseOptions: Apollo.QueryHookOptions<
    FableBriefQuery,
    FableBriefQueryVariables
  > &
    (
      | { variables: FableBriefQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FableBriefQuery, FableBriefQueryVariables>(
    FableBriefDocument,
    options,
  );
}
export function useFableBriefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FableBriefQuery,
    FableBriefQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FableBriefQuery, FableBriefQueryVariables>(
    FableBriefDocument,
    options,
  );
}
export function useFableBriefSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FableBriefQuery,
    FableBriefQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FableBriefQuery, FableBriefQueryVariables>(
    FableBriefDocument,
    options,
  );
}
export type FableBriefQueryHookResult = ReturnType<typeof useFableBriefQuery>;
export type FableBriefLazyQueryHookResult = ReturnType<
  typeof useFableBriefLazyQuery
>;
export type FableBriefSuspenseQueryHookResult = ReturnType<
  typeof useFableBriefSuspenseQuery
>;
export type FableBriefQueryResult = Apollo.QueryResult<
  FableBriefQuery,
  FableBriefQueryVariables
>;
export const FablesDocument = gql`
  query Fables {
    fables {
      id
    }
  }
`;

/**
 * __useFablesQuery__
 *
 * To run a query within a React component, call `useFablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFablesQuery(
  baseOptions?: Apollo.QueryHookOptions<FablesQuery, FablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FablesQuery, FablesQueryVariables>(
    FablesDocument,
    options,
  );
}
export function useFablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FablesQuery, FablesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FablesQuery, FablesQueryVariables>(
    FablesDocument,
    options,
  );
}
export function useFablesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FablesQuery,
    FablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FablesQuery, FablesQueryVariables>(
    FablesDocument,
    options,
  );
}
export type FablesQueryHookResult = ReturnType<typeof useFablesQuery>;
export type FablesLazyQueryHookResult = ReturnType<typeof useFablesLazyQuery>;
export type FablesSuspenseQueryHookResult = ReturnType<
  typeof useFablesSuspenseQuery
>;
export type FablesQueryResult = Apollo.QueryResult<
  FablesQuery,
  FablesQueryVariables
>;
export const UpdateCharactersDocument = gql`
  mutation UpdateCharacters($input: UpdateCharactersInput!) {
    updateCharacters(input: $input) {
      fableBrief {
        id
        characters
      }
      errors {
        message
      }
    }
  }
`;
export type UpdateCharactersMutationFn = Apollo.MutationFunction<
  UpdateCharactersMutation,
  UpdateCharactersMutationVariables
>;

/**
 * __useUpdateCharactersMutation__
 *
 * To run a mutation, you first call `useUpdateCharactersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCharactersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCharactersMutation, { data, loading, error }] = useUpdateCharactersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCharactersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCharactersMutation,
    UpdateCharactersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCharactersMutation,
    UpdateCharactersMutationVariables
  >(UpdateCharactersDocument, options);
}
export type UpdateCharactersMutationHookResult = ReturnType<
  typeof useUpdateCharactersMutation
>;
export type UpdateCharactersMutationResult =
  Apollo.MutationResult<UpdateCharactersMutation>;
export type UpdateCharactersMutationOptions = Apollo.BaseMutationOptions<
  UpdateCharactersMutation,
  UpdateCharactersMutationVariables
>;
export const UpdateIllustrationsDocument = gql`
  mutation UpdateIllustrations($input: UpdateIllustrationsInput!) {
    updateIllustrations(input: $input) {
      fableBrief {
        id
        illustrations
      }
      errors {
        message
      }
    }
  }
`;
export type UpdateIllustrationsMutationFn = Apollo.MutationFunction<
  UpdateIllustrationsMutation,
  UpdateIllustrationsMutationVariables
>;

/**
 * __useUpdateIllustrationsMutation__
 *
 * To run a mutation, you first call `useUpdateIllustrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIllustrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIllustrationsMutation, { data, loading, error }] = useUpdateIllustrationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIllustrationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIllustrationsMutation,
    UpdateIllustrationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIllustrationsMutation,
    UpdateIllustrationsMutationVariables
  >(UpdateIllustrationsDocument, options);
}
export type UpdateIllustrationsMutationHookResult = ReturnType<
  typeof useUpdateIllustrationsMutation
>;
export type UpdateIllustrationsMutationResult =
  Apollo.MutationResult<UpdateIllustrationsMutation>;
export type UpdateIllustrationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateIllustrationsMutation,
  UpdateIllustrationsMutationVariables
>;
export const UpdateLanguageDocument = gql`
  mutation UpdateLanguage($input: UpdateLanguageInput!) {
    updateLanguage(input: $input) {
      fableBrief {
        id
        language
      }
      errors {
        message
      }
    }
  }
`;
export type UpdateLanguageMutationFn = Apollo.MutationFunction<
  UpdateLanguageMutation,
  UpdateLanguageMutationVariables
>;

/**
 * __useUpdateLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLanguageMutation, { data, loading, error }] = useUpdateLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLanguageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLanguageMutation,
    UpdateLanguageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLanguageMutation,
    UpdateLanguageMutationVariables
  >(UpdateLanguageDocument, options);
}
export type UpdateLanguageMutationHookResult = ReturnType<
  typeof useUpdateLanguageMutation
>;
export type UpdateLanguageMutationResult =
  Apollo.MutationResult<UpdateLanguageMutation>;
export type UpdateLanguageMutationOptions = Apollo.BaseMutationOptions<
  UpdateLanguageMutation,
  UpdateLanguageMutationVariables
>;
export const UpdatePlotDocument = gql`
  mutation UpdatePlot($input: UpdatePlotInput!) {
    updatePlot(input: $input) {
      fableBrief {
        id
        plot
      }
      errors {
        message
      }
    }
  }
`;
export type UpdatePlotMutationFn = Apollo.MutationFunction<
  UpdatePlotMutation,
  UpdatePlotMutationVariables
>;

/**
 * __useUpdatePlotMutation__
 *
 * To run a mutation, you first call `useUpdatePlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlotMutation, { data, loading, error }] = useUpdatePlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlotMutation,
    UpdatePlotMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlotMutation, UpdatePlotMutationVariables>(
    UpdatePlotDocument,
    options,
  );
}
export type UpdatePlotMutationHookResult = ReturnType<
  typeof useUpdatePlotMutation
>;
export type UpdatePlotMutationResult =
  Apollo.MutationResult<UpdatePlotMutation>;
export type UpdatePlotMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlotMutation,
  UpdatePlotMutationVariables
>;
export const UpdateSettingDocument = gql`
  mutation UpdateSetting($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      fableBrief {
        id
        setting
      }
      errors {
        message
      }
    }
  }
`;
export type UpdateSettingMutationFn = Apollo.MutationFunction<
  UpdateSettingMutation,
  UpdateSettingMutationVariables
>;

/**
 * __useUpdateSettingMutation__
 *
 * To run a mutation, you first call `useUpdateSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingMutation, { data, loading, error }] = useUpdateSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSettingMutation,
    UpdateSettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSettingMutation,
    UpdateSettingMutationVariables
  >(UpdateSettingDocument, options);
}
export type UpdateSettingMutationHookResult = ReturnType<
  typeof useUpdateSettingMutation
>;
export type UpdateSettingMutationResult =
  Apollo.MutationResult<UpdateSettingMutation>;
export type UpdateSettingMutationOptions = Apollo.BaseMutationOptions<
  UpdateSettingMutation,
  UpdateSettingMutationVariables
>;
export const UpdateThemesDocument = gql`
  mutation UpdateThemes($input: UpdateThemesInput!) {
    updateThemes(input: $input) {
      fableBrief {
        id
        themes
      }
      errors {
        message
      }
    }
  }
`;
export type UpdateThemesMutationFn = Apollo.MutationFunction<
  UpdateThemesMutation,
  UpdateThemesMutationVariables
>;

/**
 * __useUpdateThemesMutation__
 *
 * To run a mutation, you first call `useUpdateThemesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThemesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThemesMutation, { data, loading, error }] = useUpdateThemesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThemesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThemesMutation,
    UpdateThemesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateThemesMutation,
    UpdateThemesMutationVariables
  >(UpdateThemesDocument, options);
}
export type UpdateThemesMutationHookResult = ReturnType<
  typeof useUpdateThemesMutation
>;
export type UpdateThemesMutationResult =
  Apollo.MutationResult<UpdateThemesMutation>;
export type UpdateThemesMutationOptions = Apollo.BaseMutationOptions<
  UpdateThemesMutation,
  UpdateThemesMutationVariables
>;
