import { Card, CardContent, CardFooter } from "@/components/ui/card";
import {
  useCreateFableMutation,
  useFableBriefQuery,
} from "@/gql/__generated__/gql_types";
import { findIndex, max, min, toInteger } from "lodash";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Stepper } from "../components/Stepper";
import { wordCount } from "./utils";
import { Button } from "@/components/ui/button";

const completion = (str: string): number => {
  const matchCount = wordCount(str);

  //const normalizedCount = matchCount / 5; // 500 words == 100%
  const normalizedCount = matchCount / 1; // 500 words == 100%
  return toInteger(min([max([normalizedCount, 0]), 100]));
};

const currentStepKey = "fable-brief-current-step";
export const FableBrief = () => {
  const { id: fableBriefId } = useParams() as { id: string };
  console.log("id", fableBriefId);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [createFable] = useCreateFableMutation();

  const { data } = useFableBriefQuery({ variables: { fableBriefId } });
  const fableBrief = data?.fableBrief?.fableBrief;
  const steps = [
    {
      name: "Characters",
      completion: completion(fableBrief?.characters ?? ""),
      wordCount: wordCount(fableBrief?.characters ?? ""),
    },
    {
      name: "Plot",
      completion: completion(fableBrief?.plot ?? ""),
      wordCount: wordCount(fableBrief?.plot ?? ""),
    },
    {
      name: "Setting",
      completion: completion(fableBrief?.setting ?? ""),
      wordCount: wordCount(fableBrief?.setting ?? ""),
    },
    {
      name: "Themes",
      completion: completion(fableBrief?.themes ?? ""),
      wordCount: wordCount(fableBrief?.themes ?? ""),
    },
    {
      name: "Language",
      completion: completion(fableBrief?.language ?? ""),
      wordCount: wordCount(fableBrief?.language ?? ""),
    },
    {
      name: "Illustrations",
      completion: completion(fableBrief?.illustrations ?? ""),
      wordCount: wordCount(fableBrief?.illustrations ?? ""),
    },
    {
      name: "Submit",
      completion: 0,
      wordCount: 0,
    },
  ];
  const [activeStep, setActiveStep] = useState(
    () => localStorage.getItem(currentStepKey) || steps[0].name,
  ); // Initialize active step

  const onClickSubmit = () => {
    const variables = {
      input: {
        fableBriefId,
      },
    };
    createFable({
      variables,
      onCompleted: ({ createFable }) => {
        navigate(`/fables/${createFable?.fable?.id}`);
      },
    });
  };

  const incrementActiveStep = (value = 1) => {
    let activeIndex = findIndex(steps, (s) => s.name === activeStep) ?? 0;
    activeIndex += value;
    activeIndex += steps.length;
    setActiveStep(steps[activeIndex % steps.length].name);
  };

  useEffect(() => {
    navigate(`/briefs/${fableBriefId}/${activeStep.toLowerCase()}`);
    localStorage.setItem(currentStepKey, activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (pathname.match(/^\/briefs\/[-\w\d]+\/?$/)) {
      navigate(`${pathname.replace(/(\/)$/, "")}/characters`, {
        replace: true,
      });
    }
  }, [pathname]);
  return (
    <div>
      <Card>
        <CardContent>
          <Stepper
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          <Outlet />
        </CardContent>
        <CardFooter className="flex justify-between">
          <div>
            <Button onClick={() => incrementActiveStep(-1)}>Prev</Button>
          </div>
          <div className="flex">
            <Button className="ml-2" onClick={() => incrementActiveStep(1)}>
              Next
            </Button>
            <Button className="ml-2" onClick={onClickSubmit}>
              Submit
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
