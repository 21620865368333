import { Textarea } from "@/components/ui/textarea";
import {
  UpdateThemesMutationVariables,
  useFableBriefQuery,
  useUpdateThemesMutation,
} from "@/gql/__generated__/gql_types";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mutationDebounceMS, mutationDebounceOptions } from "./utils";

export const FableBriefThemes = () => {
  const { id: fableBriefId } = useParams() as { id: string };
  const { data } = useFableBriefQuery({
    variables: { fableBriefId },
    onCompleted: ({ fableBrief }) => {
      if (fableBrief?.fableBrief) {
        setThemes(fableBrief.fableBrief.themes);
      }
      //
    },
  });
  const fableBrief = data?.fableBrief?.fableBrief;
  const [themes, setThemes] = useState(fableBrief?.themes ?? "");

  const [updateThemes] = useUpdateThemesMutation();
  const updateThemesWithDebounce = useCallback(
    debounce(
      ({ variables }: { variables: UpdateThemesMutationVariables }) => {
        updateThemes({ variables });
      },
      mutationDebounceMS,
      mutationDebounceOptions,
    ),
    [updateThemes],
  );

  useEffect(() => {
    updateThemesWithDebounce({
      variables: {
        input: {
          fableBriefId,
          themes,
        },
      },
    });
  }, [themes]);
  return (
    <div>
      <h1>Themes</h1>
      <div className="flex">
        <div className="w-1/3">
          <p>What themes do you want to cover</p>
        </div>
        <div className="w-2/3">
          <Textarea
            value={themes}
            onChange={(e) => setThemes(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
