import { Textarea } from "@/components/ui/textarea";
import {
  UpdateCharactersMutationVariables,
  useFableBriefQuery,
  useUpdateCharactersMutation,
} from "@/gql/__generated__/gql_types";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  mutationDebounceMS,
  mutationDebounceOptions,
  wordCount,
} from "./utils";

export const FableBriefCharacters = () => {
  const { id: fableBriefId } = useParams() as { id: string };
  const { data } = useFableBriefQuery({
    variables: { fableBriefId },
    onCompleted: ({ fableBrief }) => {
      if (fableBrief?.fableBrief) {
        setCharacters(fableBrief.fableBrief.characters);
      }
      //
    },
  });
  const fableBrief = data?.fableBrief?.fableBrief;
  const [characters, setCharacters] = useState(fableBrief?.characters ?? "");

  const [updateCharacters] = useUpdateCharactersMutation();
  const updateCharactersWithDebounce = useCallback(
    debounce(
      ({ variables }: { variables: UpdateCharactersMutationVariables }) => {
        updateCharacters({ variables });
      },
      mutationDebounceMS,
      mutationDebounceOptions,
    ),
    [updateCharacters],
  );

  useEffect(() => {
    updateCharactersWithDebounce({
      variables: {
        input: {
          fableBriefId,
          characters,
        },
      },
    });
  }, [characters]);
  return (
    <div>
      <h1>Characters</h1>
      <div className="flex">
        <div className="w-1/3">
          <p>This is how you should create your characters</p>
        </div>
        <div className="w-2/3">
          <Textarea
            value={characters}
            onChange={(e) => setCharacters(e.target.value)}
            wordCount={wordCount(characters)}
          />
        </div>
      </div>
    </div>
  );
};
