import { Textarea } from "@/components/ui/textarea";
import {
  UpdateIllustrationsMutationVariables,
  useFableBriefQuery,
  useUpdateIllustrationsMutation,
} from "@/gql/__generated__/gql_types";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mutationDebounceMS, mutationDebounceOptions } from "./utils";
import flatSource from "../assets/flat-style.webp";
import thinLineSource from "../assets/thin-lines.webp";

export const FableBriefIllustrations = () => {
  const { id: fableBriefId } = useParams() as { id: string };
  const { data } = useFableBriefQuery({
    variables: { fableBriefId },
    onCompleted: ({ fableBrief }) => {
      if (fableBrief?.fableBrief) {
        setIllustrations(fableBrief.fableBrief.illustrations);
      }
    },
  });
  const fableBrief = data?.fableBrief?.fableBrief;
  const [illustrations, setIllustrations] = useState(
    fableBrief?.illustrations ?? "",
  );

  const [updateIllustrations] = useUpdateIllustrationsMutation();
  const updateIllustrationsWithDebounce = useCallback(
    debounce(
      ({ variables }: { variables: UpdateIllustrationsMutationVariables }) => {
        updateIllustrations({ variables });
      },
      mutationDebounceMS,
      mutationDebounceOptions,
    ),
    [updateIllustrations],
  );

  useEffect(() => {
    updateIllustrationsWithDebounce({
      variables: {
        input: {
          fableBriefId,
          illustrations,
        },
      },
    });
  }, [illustrations]);
  return (
    <div>
      <h1>Illustrations</h1>
      <div className="flex">
        <div className="w-1/3">
          <p>describe the illustration style</p>
        </div>
        <div className="w-2/3">
          <div className="flex gap-4 py-4">
            <img width={300} src={flatSource} />
            <img width={300} src={thinLineSource} />
          </div>
          <Textarea
            value={illustrations}
            onChange={(e) => setIllustrations(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
