import { useFableQuery } from "@/gql/__generated__/gql_types";
import { useParams } from "react-router-dom";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { sortBy } from "lodash";

export const Fable = () => {
  const { id: fableId } = useParams() as { id: string };
  const { data } = useFableQuery({ variables: { fableId } });
  const fable = data?.fable?.fable;
  const pages = fable?.pages ?? [];
  const progress = fable?.progress ?? 0;

  const sortedPages = sortBy(pages, [(p) => p.pageNumber]);
  return (
    <div>
      <div className="w-full flex justify-center">
        <h1>{fable?.title}</h1>
      </div>
      <div className="w-full flex justify-center">
        <Carousel className=" w-full max-w-screen-xl border">
          <CarouselContent>
            {sortedPages.map((p) => {
              return (
                <CarouselItem>
                  <div className="grid grid-cols-2">
                    <div className="w-full flex col-span-1">
                      <p className="text-4xl p-8 self-center">{p.text}</p>
                    </div>
                    <div className="w-full col-span-1">
                      <p className="text-4xl p-8">
                        {p.illustrationUrl ? (
                          <img src={p.illustrationUrl ?? ""} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full justify-center pb-8 pt-0">
                    {p.pageNumber}
                  </div>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
      <div className="mt-24">
        <p>Fable ID: {fableId}</p>
        <p>Progress: {progress}</p>
        <pre>{JSON.stringify(fable, null, 2)}</pre>
      </div>
    </div>
  );
};
