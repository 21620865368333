import { Outlet } from "react-router-dom";
import ParamMessage from "../components/ParamMessage";
import { AppBar } from "@/components/AppBar";

export const Root = () => {
  return (
    <div className="container mx-auto p-0">
      <ParamMessage />
      <AppBar />
      <div className="p-4">
        <Outlet />
      </div>
    </div>
  );
};
