import "./App.css";
import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apolloClient";
import { ThemeProvider } from "./components/ThemeProvider";

const browserRouter = createBrowserRouter(routes);

const App = () => (
  <>
    <ApolloProvider client={client}>
      <ThemeProvider defaultTheme="dark" storageKey="ui-theme">
        <RouterProvider router={browserRouter} />
      </ThemeProvider>
    </ApolloProvider>
  </>
);

export default App;
