import { createContext, useContext, useEffect, useState } from "react";

type ThemePreference = "dark" | "light" | "system";
type Theme = "dark" | "light";

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: ThemePreference;
  storageKey?: string;
};

type ThemeProviderState = {
  themePreference: ThemePreference;
  theme: Theme;
  setThemePreference: (themePreference: ThemePreference) => void;
};

const initialState: ThemeProviderState = {
  themePreference: "system",
  theme: "dark",
  setThemePreference: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultTheme = "system",
  storageKey = "vite-ui-theme",
  ...props
}: ThemeProviderProps) {
  const [themePreference, setThemePreference] = useState<ThemePreference>(
    () => (localStorage.getItem(storageKey) as ThemePreference) || defaultTheme,
  );
  const [theme, setTheme] = useState<Theme>(
    () =>
      (localStorage.getItem(storageKey) === "light"
        ? "light"
        : "dark") as Theme,
  );

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove("light", "dark");
    if (themePreference === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";

      setTheme(systemTheme);

      root.classList.add(systemTheme);
      return;
    } else {
      setTheme(themePreference);
    }

    root.classList.add(themePreference);
  }, [themePreference]);

  const value = {
    theme,
    themePreference,
    setThemePreference: (themePreference: ThemePreference) => {
      localStorage.setItem(storageKey, themePreference);
      setThemePreference(themePreference);
    },
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined)
    throw new Error("useTheme must be used within a ThemeProvider");

  return context;
};
