import { Textarea } from "@/components/ui/textarea";
import {
  UpdateSettingMutationVariables,
  useFableBriefQuery,
  useUpdateSettingMutation,
} from "@/gql/__generated__/gql_types";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mutationDebounceMS, mutationDebounceOptions } from "./utils";

export const FableBriefSetting = () => {
  const { id: fableBriefId } = useParams() as { id: string };
  const { data } = useFableBriefQuery({
    variables: { fableBriefId },
    onCompleted: ({ fableBrief }) => {
      if (fableBrief?.fableBrief) {
        setSetting(fableBrief.fableBrief.setting);
      }
      //
    },
  });
  const fableBrief = data?.fableBrief?.fableBrief;
  const [setting, setSetting] = useState(fableBrief?.setting ?? "");

  const [updateSetting] = useUpdateSettingMutation();
  const updateSettingWithDebounce = useCallback(
    debounce(
      ({ variables }: { variables: UpdateSettingMutationVariables }) => {
        updateSetting({ variables });
      },
      mutationDebounceMS,
      mutationDebounceOptions,
    ),
    [updateSetting],
  );

  useEffect(() => {
    updateSettingWithDebounce({
      variables: {
        input: {
          fableBriefId,
          setting,
        },
      },
    });
  }, [setting]);
  return (
    <div>
      <h1>Setting</h1>
      <div className="flex">
        <div className="w-1/3">
          <p>This is how you should create your setting</p>
        </div>
        <div className="w-2/3">
          <Textarea
            value={setting}
            onChange={(e) => setSetting(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
