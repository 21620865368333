import { Textarea } from "@/components/ui/textarea";
import {
  UpdateLanguageMutationVariables,
  useFableBriefQuery,
  useUpdateLanguageMutation,
} from "@/gql/__generated__/gql_types";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mutationDebounceMS, mutationDebounceOptions } from "./utils";

export const FableBriefLanguage = () => {
  const { id: fableBriefId } = useParams() as { id: string };
  const { data } = useFableBriefQuery({
    variables: { fableBriefId },
    onCompleted: ({ fableBrief }) => {
      if (fableBrief?.fableBrief) {
        setLanguage(fableBrief.fableBrief.language);
      }
      //
    },
  });
  const fableBrief = data?.fableBrief?.fableBrief;
  const [language, setLanguage] = useState(fableBrief?.language ?? "");

  const [updateLanguage] = useUpdateLanguageMutation();
  const updateLanguageWithDebounce = useCallback(
    debounce(
      ({ variables }: { variables: UpdateLanguageMutationVariables }) => {
        updateLanguage({ variables });
      },
      mutationDebounceMS,
      mutationDebounceOptions,
    ),
    [updateLanguage],
  );

  useEffect(() => {
    updateLanguageWithDebounce({
      variables: {
        input: {
          fableBriefId,
          language,
        },
      },
    });
  }, [language]);
  return (
    <div>
      <h1>Language</h1>
      <div className="flex">
        <div className="w-1/3">
          <p>What kind of language should we use?</p>
        </div>
        <div className="w-2/3">
          <Textarea
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
